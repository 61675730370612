<template>
    <v-container class="py-5 grey lighten-2" fluid>
        <v-row justify="center" align="center">
            <v-col cols="12" class="" justify="center" align="center">
                <v-card class="justify-center" elevation="2" max-width="450px">
                    <v-card-title class="grey lighten-3">Generar Orden de Producción</v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row align-content="center" justify="center" align="center">
                                <v-col cols="12">
                                    <v-autocomplete v-model="form.vendedora" :rules="vendedoraRules" v-if="personal"
                                        :items="personal" label="Vendedor"></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete v-if="checkbox" dense v-model="producto" v-validate="'required'"
                                        label="Producto" :items="_listaProductosOut" data-vv-as="marca"
                                        data-vv-name="servicio" required item-text="rename" item-value="id"
                                        return-object>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete v-model="form.personalCargo" :rules="personalCargoRules"
                                        v-if="personal" :items="personal" label="Personal a Cargo"></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="form.observaciones" name="input-7-1" label="Observaciones"
                                        value=""></v-textarea>
                                </v-col>
                                <v-col cols="12" text-center>
                                    <v-btn block :disabled="!valid" :loading="loading" color="success" class="mr-4"
                                        @click="validate"> Generar </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { parse } from 'date-fns'
import { mapState } from 'vuex'
import { formatInTimeZone } from 'date-fns-tz'

export default {
    computed: {

        ...mapState('users', ['user', 'personal', 'listaUser']),
        ...mapState('productos', ['listaProductos', 'dataproducto']),

        _listaProductosOut() {
            let listaProductos = []
            //console.log('this.obtenerVenta__listaProductosOut', this.obtenerVenta)
            if (this.listaProductos) {
                //console.log('this.dataproducto', this.dataproducto)
                if (this.dataproducto) {
                    this.dataproducto.map((v) => {
                        const tMoneda = v.idTipoMoneda == 1 ? 'S/' : '$'
                        listaProductos.push({
                            ...v,
                            comision: 0,
                            descuentos: 0,
                            unidades: 1,
                            rename: ` ${tMoneda} ${v.price} | ${v.description} | ${v.model} | ${v.internal_id}`
                        })
                    })
                }
                return listaProductos
            }
        }
    },
    data() {
        return {
            dataVenta: '',
            dataProducto: '',
            valid: true,
            vendedoras: '',
            personalCargo: '',
            name: '',
            obtenerVenta: null,
            email: '',
            producto: '',
            loading: false,
            select: null,
            checkbox: false,
            vendedoraRules: [(v) => !!v || 'Vendedora is required'],
            personalCargoRules: [(v) => !!v || 'Personal a cargo is required'],
            form: {
                vendedora: '',
                observaciones: '',
                personalCargo: ''
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('users/getPersonas')
        console.log("this.listaUser ", this.user.id, this.listaUser)
        this.form.vendedora = this.listaUser.find((x) => x.id == this.user.id).nombres

        let obtenerProducto
        const obtenerVenta = await this.$store.dispatch('ventas/obtenerVenta', {
            id: this.$route.query.idVenta
        })
        this.obtenerVenta = JSON.parse(obtenerVenta ? obtenerVenta.productos : null)
        //console.log('this.obtenerVenta', this.obtenerVenta)
        obtenerProducto = this.dataproducto.find((x) => x.id === Number(this.$route.query.idProducto))
        //console.log('_______obtenerProducto', obtenerProducto)

        if (!obtenerProducto) {
            //console.log('ara')
            if (obtenerVenta) {
                obtenerProducto = JSON.parse(obtenerVenta.productos).find((x) => x.id === Number(this.$route.query.idProducto))
            } else {
                this.checkbox = true
            }
        }
        //console.log('dataVenta', this.dataVenta)
        this.dataVenta = obtenerVenta
        this.dataProducto = obtenerProducto
        //console.log('user', this.user)
    },
    methods: {
        async validate() {
            const validacion = await this.$refs.form.validate()
            var today = new Date()
            var dd = today.getDate()
            var mm = today.getMonth() + 1 //January is 0!
            var yyyy = today.getFullYear()
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            today = yyyy + '-' + mm + '-' + dd
            const ordenPedido = this.$route.query.idVenta
            console.log('ordenPedido', ordenPedido)
            console.log(' this.form', this.form)
            console.log('this.producto', this.producto)
            if (validacion) {
                this.loading = true

                const OrdenDeProduccion = await this.$store.dispatch('ventas/crearOrdenPedido', {
                    vendedor: this.form.vendedora,
                    fechaEmision: today,
                    personalCargo: this.form.personalCargo,
                    ordenPedido,
                    producto: this.producto ? this.producto.description : this.dataProducto.description,
                    color: this.producto ? this.producto.color : this.dataProducto.color,
                    medida: this.producto ? this.producto.medida : this.dataProducto.medida,
                    material: 'FIBRA DE VIDRIO',
                    observaciones: this.form.observaciones
                })
                //console.log('ordeProduction')
                this.$store.dispatch('reporte/reporteOrdenProduccion', {
                    id: OrdenDeProduccion.data.id,
                    annio: OrdenDeProduccion.data.fechaEmision.split('-')[0],
                    vendedora: this.form.vendedora,
                    fechaEmision: OrdenDeProduccion.data.fechaEmision,
                    ordenPedido: OrdenDeProduccion.data.ordenPedido,
                    personalCargo: this.form.personalCargo,
                    producto: this.producto ? this.producto.description : this.dataProducto.description,
                    color: this.producto ? this.producto.color : this.dataProducto.color,
                    medida: this.producto ? this.producto.medida : this.dataProducto.medida,
                    material: OrdenDeProduccion.data.material,
                    observacion: this.form.observaciones
                })
                this.loading = false
                this.$router.push(`/logistica/orden-produccion/lista`)
            }
        }
    }
}
</script>
